import 'lazyload'

document.addEventListener('DOMContentLoaded', () => {
	document.documentElement.scrollTop = 0

	const detectOS = () =>
		/android/i.test(navigator.userAgent)
			? 'Android'
			: /iPad|iPhone|iPod/.test(navigator.userAgent)
			? 'iOS'
			: 'Неустановленная ОС'

	const isIOS = detectOS() == 'iOS'
	const isAndroid = detectOS() == 'Android'

	function setWindowHeight() {
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	// Устанавливаем при загрузке
	setWindowHeight()

	// Сбрасываем при изменении размера окна
	window.addEventListener('resize', setWindowHeight)

	function preventContextMenu(selector) {
		const allElements = document.querySelectorAll(selector)

		allElements.forEach(elem => {
			elem.oncontextmenu = e => {
				e.preventDefault()
				return false
			}
		})
	}

	const lazyImages = document.querySelectorAll('.js-lazy')

	new lazyload(lazyImages, {
		root: null,
	})
	const pageSliderParents = document.querySelectorAll('[data-slider="true"]')

	function initPageSlider() {
		pageSliderParents.forEach((slider, i) => {
			// if (document.querySelector(`.page-slider_${i + 1}`)) return;

			slider.dataset.pageSlider = `${i + 1}`

			const curSlider = document.createElement('div')
			const sliderImages = slider.querySelectorAll('img')
			const pageSliderTrack = document.createElement('div')
			const sliderCLose = document.createElement('div')
			const sliderPrev = document.createElement('div')
			const sliderNext = document.createElement('div')

			sliderCLose.classList.add('page-slider__close')
			sliderCLose.appendChild(document.createElement('div'))
			sliderCLose.appendChild(document.createElement('div'))
			sliderPrev.classList.add('page-slider__prevBtn')
			sliderPrev.classList.add('icon-prev')
			sliderNext.classList.add('page-slider__nextBtn')
			sliderNext.classList.add('icon-next')

			curSlider.classList.add(`page-slider_${i + 1}`)
			curSlider.classList.add(`page-slider`)
			pageSliderTrack.classList.add('page-slider__track')

			curSlider.appendChild(sliderCLose)
			curSlider.appendChild(sliderPrev)
			curSlider.appendChild(sliderNext)

			sliderImages.forEach((image, i) => {
				const curImg = image.cloneNode()
				image.dataset.image = `${i + 1}`
				curImg.dataset.image = `${i + 1}`
				pageSliderTrack.appendChild(curImg)
			})

			curSlider.dataset.pageSlider = `${i + 1}`
			curSlider.appendChild(pageSliderTrack)
			document.body.appendChild(curSlider)
		})

		const pageSliders = document.querySelectorAll('.page-slider')
		pageSliderParents.forEach((slider, i) => {
			const sliderKeys = slider.querySelectorAll('img')

			sliderKeys.forEach((btn, i) => {
				btn.onclick = () => {
					pageSliders.forEach((slider, idx) => {
						if (
							btn.closest('[data-page-slider]').dataset.pageSlider ===
							slider.dataset.pageSlider
						) {
							const slides = slider.querySelectorAll('img')
							const sliderTrack = slider.querySelector('.page-slider__track')

							slider.style.display = 'block'
							sliderTrack.style.transition = 'opacity 0.3s, transform 0s'
							sliderTrack.style.transform = `translate(${
								-slides[i].clientWidth * i - 1000 * i
							}px, -50%)`
							setTimeout(() => {
								slider.style.opacity = '1'
								sliderTrack.style.transition = 'all 0.3s ease'
							}, 0)
						} else return
					})
				}
			})
		})

		pageSliders.forEach(slider => {
			const closeSlider = slider.querySelector('.page-slider__close')
			const sliderTrack = slider.querySelector('.page-slider__track')
			const sliderImages = slider.querySelectorAll('img')
			const sliderPrevBtn = slider.querySelector('.page-slider__prevBtn')
			const sliderNextBtn = slider.querySelector('.page-slider__nextBtn')

			closeSlider.onclick = () => {
				slider.style.opacity = '0'
				setTimeout(() => {
					slider.style.display = 'none'
					sliderTrack.style.transform = 'translate(0px, -50%)'
				}, 310)
			}

			sliderPrevBtn.onclick = () => {
				if (sliderTrack.style.transform.slice(10, -9) == 0) {
					sliderTrack.style.transform = `translate(-${
						sliderImages[0].clientWidth * sliderImages.length +
						1000 * sliderImages.length -
						sliderImages[0].clientWidth -
						1000
					}px, -50%)`
				} else {
					sliderTrack.style.transform = `translate(${
						+sliderTrack.style.transform.slice(10, -9) +
						sliderImages[0].clientWidth +
						1000
					}px, -50%)`
				}
			}

			sliderNextBtn.onclick = () => {
				if (
					sliderTrack.style.transform.slice(10, -9) ==
					-sliderImages[0].clientWidth * sliderImages.length -
						1000 * sliderImages.length +
						sliderImages[0].clientWidth +
						1000
				) {
					sliderTrack.style.transform = `translate(-0px, -50%)`
				} else {
					sliderTrack.style.transform = `translate(${
						sliderTrack.style.transform.slice(10, -9) -
						sliderImages[0].clientWidth -
						1000
					}px, -50%)`
				}
			}
		})

		pageSliders.forEach(slider => {
			slider.addEventListener('touchstart', pageSliderTouchStart, false)
			slider.addEventListener('touchmove', pageSliderTouchMove, false)
			slider.addEventListener('touchend', pageSliderTouchEnd, false)

			const sliderTrack = slider.querySelector('.page-slider__track')
			const sliderPrevBtn = slider.querySelector('.page-slider__prevBtn')
			const sliderNextBtn = slider.querySelector('.page-slider__nextBtn')
			const pageSliderImages = slider.querySelectorAll('img')

			new lazyload(pageSliderImages, {
				root: null,
			})

			let sliderTouchStartX = null
			let sliderTouchMoveX = null
			let sliderTouchDiffX = null

			function pageSliderTouchStart(event) {
				sliderTouchStartX = event.touches[0].clientX
			}

			function pageSliderTouchMove(event) {
				if (!sliderTouchStartX) return
				sliderTouchMoveX = event.touches[0].clientX
				sliderTouchDiffX = sliderTouchMoveX - sliderTouchStartX
			}

			function pageSliderTouchEnd(event) {
				if (sliderTouchDiffX > -50 && sliderTouchDiffX < 50) return
				if (sliderTouchDiffX >= 50) {
					sliderPrevBtn.click()
				} else if (sliderTouchDiffX <= -50) {
					sliderNextBtn.click()
				}
				sliderTouchDiffX = null
			}
		})
	}

	function app() {
		'use strict'

		const sections = document.querySelectorAll('.section')
		const menuLinks = document.querySelectorAll('.menu__link')
		const projects = document.querySelectorAll('.project')
		const popup = document.querySelector('.popup')
		const popupDeactivator = document.querySelector('.popup__deactivator')
		const popupCloseBtn = document.querySelector('.popup__close-btn')
		const popupWrappers = document.querySelectorAll('.popup__wrapper')
		const popupTracks = document.querySelectorAll('.popup__track')
		const popupPrevBtn = document.querySelector('#prev')
		const popupNextBtn = document.querySelector('#next')
		const popupButtons = document.querySelector('.popup__buttons')
		const popupBtns = document.querySelectorAll('.popup__btn')
		const menuBtn = document.querySelector('.menu-btn')
		const menu = document.querySelector('.menu')
		const main = document.querySelector('.main')
		const projectGallery = document.querySelector('.project-gallery')
		const projectGalleryBackBtn = document.querySelector(
			'.project-gallery__back'
		)
		const projectGalleryTitle = document.querySelector(
			'.project-gallery__title'
		)
		const projectGalleryDescr = document.querySelector(
			'.project-gallery__descr'
		)
		const projectGalleryBox = document.querySelector('#project-gallery-box')
		const realizationBannerText = document.querySelectorAll(
			'.realization__banner-text'
		)
		const promoAdvantagesList = document.querySelector(
			'.promo__wrapper_desktop .promo__advantages'
		)
		const mobPromoAdvantagesList = document.querySelector(
			'.promo__wrapper_mobile .promo__advantages'
		)
		const promoAdvantages = document.querySelectorAll(
			'.promo__wrapper_desktop .promo__advantage'
		)
		const mobPromoAdvantages = document.querySelectorAll(
			'.promo__wrapper_mobile .promo__advantage'
		)
		// Отдельные переменные для секции проектов и для ссылки в меню на эту секцию. Используются неоднократо
		const workSection = document.querySelector('.section.work')
		const workMenuLink = document.querySelector('.menu__link.work-link')

		// Выравнивание текста раздела "Реализация"
		realizationBannerText.forEach(item => {
			const arrWords = item.textContent
				.trim()
				.split(/\s+/)
				.filter(word => word.length > 0) // Разбиваем текст на слова, удаляем пустые строки

			item.innerHTML = '' // Очищаем текущий контент элемента

			arrWords.forEach(word => {
				const wordElem = document.createElement('span') // Создаем новый элемент span
				wordElem.textContent = word // Используем textContent для добавления текста в элемент

				// Добавляем пробел после слова, чтобы сохранить исходное форматирование
				item.appendChild(wordElem)
				item.appendChild(document.createTextNode(' ')) // Добавляем пробел между словами
			})
		})

		// Отключчение drag & drop для всех изображений
		const images = document.querySelectorAll('img')
		images.forEach(img => {
			img.ondragstart = () => false
		})

		// Закрытие окна меню по клику вне этого окна
		main.onclick = () => {
			menu.classList.remove('menu_active')
			menuBtn.classList.remove('menu-btn_active')
		}

		const activeSection = document.querySelector('.section_active')
		const activeMenuLink = document.querySelector('.menu__link_active')

		// Функции асинхронного скрытия и появления элемента
		function showElement(element, timeout = 0, displayProperty = 'block') {
			setTimeout(() => {
				element.style.display = displayProperty
			}, 0)
			setTimeout(() => {
				element.style.opacity = 1
			}, timeout)
		}

		function hideElement(element, timeout = 0) {
			element.style.opacity = 0
			setTimeout(() => {
				element.style.display = 'none'
			}, timeout)
		}

		function hideElements(elements, timeout = 0) {
			elements.forEach(element => {
				element.style.opacity = 0
				setTimeout(() => {
					element.style.display = 'none'
				}, timeout)
			})
		}

		// Отдельная функция для открытия указанной секции
		function showAnySection(section, menuLink = null) {
			section.classList.add('section_active')
			if (menuLink) {
				menuLink.classList.add('menu__link_active')
			}
			showElement(section, 400)
		}

		// Функции галереи проекта
		function createProjectGalleryRow() {
			const row = document.createElement('div')
			row.classList.add('project-gallery__imageRow')
			return row
		}

		function cleanProjectGallery() {
			projectGalleryTitle.innerHTML = null
			projectGalleryDescr.innerHTML = null
			projectGalleryBox.innerHTML = null
			projectGallery.classList.remove('project-gallery_active')
			projectGallery.dataset.imageSource = ''
		}

		function backToProjects() {
			projectGallery.classList.remove('section_active')
			hideElement(projectGallery, 400)
			setTimeout(() => {
				cleanProjectGallery()
			}, 400)
			showAnySection(workSection, workMenuLink)
		}

		// Скрипт смены активной секции по клику на ссылки меню
		for (let i = 0; i < menuLinks.length; i++) {
			menuLinks[i].onclick = () => {
				if (!menuLinks[i].classList.contains('menu__link_active')) {
					const activeSection = document.querySelector('.section_active')
					const activeMenuLink = document.querySelector('.menu__link_active')
					document.documentElement.scrollTop = 0

					activeSection.classList.remove('section_active')
					if (activeMenuLink) {
						activeMenuLink.classList.remove('menu__link_active')
					}

					sections[i].classList.add('section_active')
					menuLinks[i].classList.add('menu__link_active')

					hideElement(activeSection, 400)
					showElement(sections[i], 400)

					if (projectGalleryTitle.innerHTML) {
						setTimeout(() => {
							cleanProjectGallery()
						}, 400)
					}
				} else {
					return false
				}
			}
		}
		hideElements(sections, 0)
		showElement(activeSection, 0)

		// Функция открытия галереи проекта на каждом проекте
		projects.forEach((proj, idx) => {
			proj.onclick = () => {
				const activeSection = document.querySelector('.section_active')
				const activeMenuLink = document.querySelector('.menu__link_active')

				activeMenuLink.classList.remove('menu__link_active')
				activeSection.classList.remove('section_active')
				projectGallery.classList.add('section_active')
				projectGallery.dataset.imageSource = `${idx + 1}`

				projectGalleryTitle.innerHTML = proj.dataset.name
				projectGalleryDescr.innerHTML = `
				${proj.dataset.descr} 
				<br>
				${proj.dataset.location}
				`

				const curGallery = document.querySelector(`#proj${idx + 1}-popup`)
				const curGalleryImages = curGallery.querySelectorAll('.popup__img')
				let curRow = createProjectGalleryRow()

				curGalleryImages.forEach(img => {
					const curImg = img.cloneNode()
					const curImgWrapper = document.createElement('div')
					curImgWrapper.classList.add('project-gallery__img-wrapper')
					curImg.classList.remove('popup__img')
					curImg.classList.add('project-gallery__img')
					curImgWrapper.appendChild(curImg)

					let imgWidth
					let imgHeight
					let curObserveImg
					let imgCenterX
					let imgCenterY
					let imgX
					let imgY
					let touchStartX
					let touchStartY
					let touchMoveX
					let touchMoveY
					let touchDiffX
					let touchDiffY
					let curWrapper
					let scrollPos
					let isDragging = false

					if (!isIOS && !isAndroid) {
						curImgWrapper.onmouseover = event => {
							const curWrapper = event.target.closest(
								'.project-gallery__img-wrapper'
							)

							const curImg = curWrapper.querySelector('img')
							imgWidth = curWrapper.getBoundingClientRect().width
							imgHeight = curWrapper.getBoundingClientRect().height
							imgX = curWrapper.getBoundingClientRect().x
							imgY = curWrapper.getBoundingClientRect().y
							imgCenterX = imgX + imgWidth / 2
							imgCenterY = imgY + imgHeight / 2

							curImgWrapper.onmousemove = e => {
								let moveImageX = imgCenterX - e.clientX
								let moveImageY = imgCenterY - e.clientY
								curImg.style.transform = 'scale(2)'

								if (
									moveImageX <= imgWidth / 2 &&
									moveImageY <= imgHeight / 2 &&
									moveImageX >= -imgWidth / 2 &&
									moveImageY >= -imgHeight / 2
								) {
									curImg.style.left = `${moveImageX}px`
									curImg.style.top = `${moveImageY}px`
								} else if (
									moveImageX <= imgWidth / 2 &&
									moveImageY >= imgHeight / 2 &&
									moveImageX >= -imgWidth / 2
								) {
									curImg.style.left = `${moveImageX}px`
								} else if (
									moveImageX <= imgWidth / 2 &&
									moveImageY <= -imgHeight / 2 &&
									moveImageX >= -imgWidth / 2
								) {
									curImg.style.left = `${moveImageX}px`
								}
							}
						}

						curImgWrapper.onmouseout = e => {
							const curWrapper = e.target.closest(
								'.project-gallery__img-wrapper'
							)
							const curImg = curWrapper.querySelector('img')

							curImg.style.transform = 'scale(1)'
							curImg.style.left = '0px'
							curImg.style.top = '0px'
						}
					} else {
						false
						function observeImage(e) {
							let moveImageX = imgCenterX - e.touches[0].pageX
							let moveImageY = imgCenterY - e.touches[0].pageY

							if (
								moveImageX <= imgWidth / 2 &&
								moveImageY <= imgHeight / 2 &&
								moveImageX >= -imgWidth / 2 &&
								moveImageY >= -imgHeight / 2
							) {
								requestAnimationFrame(() => {
									curObserveImg.style.left = `${moveImageX}px`
									curObserveImg.style.top = `${moveImageY}px`
								})
							} else if (
								moveImageX <= imgWidth / 2 &&
								moveImageY >= imgHeight / 2 &&
								moveImageX >= -imgWidth / 2
							) {
								requestAnimationFrame(() => {
									curObserveImg.style.left = `${moveImageX}px`
								})
							} else if (
								moveImageX >= imgWidth / 2 &&
								moveImageY <= imgHeight / 2 &&
								moveImageY >= -imgHeight / 2
							) {
								requestAnimationFrame(() => {
									curObserveImg.style.top = `${moveImageY}px`
								})
							} else if (
								moveImageX <= -imgWidth / 2 &&
								moveImageY <= imgHeight / 2 &&
								moveImageY >= -imgHeight / 2
							) {
								requestAnimationFrame(() => {
									curObserveImg.style.top = `${moveImageY}px`
								})
							} else if (
								moveImageX <= imgWidth / 2 &&
								moveImageY <= -imgHeight / 2 &&
								moveImageX >= -imgWidth / 2
							) {
								requestAnimationFrame(() => {
									curObserveImg.style.left = `${moveImageX}px`
								})
							} else {
								false
							}
						}

						if (!isIOS) {
							document.ontouchstart = event => {
								curWrapper = event.target.closest(
									'.project-gallery__img-wrapper'
								)
								if (!curWrapper) return

								touchStartX = event.touches[0].pageX
								touchStartY = event.touches[0].pageY

								document.addEventListener('touchmove', ev => {
									if (!curWrapper) return

									touchMoveX = ev.touches[0].clientX
									touchMoveY = ev.touches[0].clientY
									touchDiffX = touchMoveX - touchStartX
									touchDiffY = touchMoveY - touchStartY
								})

								curObserveImg = curWrapper.querySelector('img')
								imgWidth = curWrapper.getBoundingClientRect().width
								imgHeight = curWrapper.getBoundingClientRect().height
								imgX = curWrapper.getBoundingClientRect().x
								imgY = curWrapper.offsetTop + projectGallery.offsetTop
								imgCenterX = imgX + imgWidth / 2
								imgCenterY = imgY + imgHeight / 2
								scrollPos = window.scrollY

								const needRunObserve = new Promise((resolve, reject) => {
									setTimeout(() => {
										if (
											touchStartX &&
											(!touchDiffY || (touchDiffY < 2 && touchDiffY > -2))
										) {
											isDragging = true
											document.documentElement.style.overflowY = 'hidden'
											document.body.style.overflowY = 'hidden'
											document.body.style.height = `${
												document.body.getBoundingClientRect().height +
												projectGallery.getBoundingClientRect().height
											}px`

											window.scrollTo({
												top: scrollPos,
												behavior: 'instant',
											})

											setWindowHeight()

											window.addEventListener('scroll', e => {
												e.preventDefault()
												e.stopPropagation()
											})
											document.addEventListener('scroll', e => {
												e.preventDefault()
												e.stopPropagation()
											})
											document.body.addEventListener('scroll', e => {
												e.preventDefault()
												e.stopPropagation()
											})
											document.documentElement.addEventListener('scroll', e => {
												e.preventDefault()
												e.stopPropagation()
											})
											resolve()
										} else {
											reject()
										}
									}, 125)
								})

								needRunObserve
									.then(() => {
										let moveImageX = imgCenterX - event.touches[0].pageX
										let moveImageY = imgCenterY - event.touches[0].pageY

										curObserveImg.style.transform = 'scale(2)'

										document.oncontextmenu = event => {
											if (
												event.target.closest(
													'.project-gallery__img-wrapper img'
												)
											) {
												event.preventDefault()
											}
										}

										if (
											moveImageX <= imgWidth / 2 &&
											moveImageY <= imgHeight / 2 &&
											moveImageX >= -imgWidth / 2 &&
											moveImageY >= -imgHeight / 2
										) {
											requestAnimationFrame(() => {
												curObserveImg.style.left = `${moveImageX}px`
												curObserveImg.style.top = `${moveImageY}px`
											})
											setTimeout(() => {
												if (!touchDiffX && !touchDiffY) {
													requestAnimationFrame(() => {
														curObserveImg.style.left = `${moveImageX}px`
														curObserveImg.style.top = `${moveImageY}px`
													})
												}
											}, 370)
										} else {
											false
										}

										document.addEventListener('touchmove', observeImage)
									})
									.catch(() => {
										return false
									})
							}

							document.ontouchend = e => {
								if (!curWrapper) return

								const curImg = curWrapper.querySelector('img')

								document.documentElement.style.overflowY = 'visible'
								document.body.style.overflowY = 'auto'
								document.body.style.height = 'auto'
								if (isDragging) {
									window.scroll({
										left: 0,
										top: scrollPos,
										behavior: 'instant',
									})
								}
								window.removeEventListener('scroll', e => {
									e.preventDefault()
									e.stopPropagation()
								})
								document.removeEventListener('scroll', e => {
									e.preventDefault()
									e.stopPropagation()
								})
								document.body.removeEventListener('scroll', e => {
									e.preventDefault()
									e.stopPropagation()
								})
								document.documentElement.removeEventListener('scroll', e => {
									e.preventDefault()
									e.stopPropagation()
								})
								requestAnimationFrame(() => {
									curImg.style.transform = 'scale(1)'
									curImg.style.left = '0px'
									curImg.style.top = '0px'
								})
								document.removeEventListener('touchmove', observeImage)
								document.removeEventListener('touchmove', ev => {
									if (!curWrapper) return

									touchMoveX = ev.touches[0].clientX
									touchMoveY = ev.touches[0].clientY
									touchDiffX = touchMoveX - touchStartX
									touchDiffY = touchMoveY - touchStartY
								})

								touchStartX = null
								touchStartY = null
								touchMoveX = null
								touchMoveY = null
								touchDiffX = null
								touchDiffY = null
								imgY = null
								curWrapper = null
								isDragging = false
							}
						} else {
							document.ontouchstart = event => {
								curWrapper = event.target.closest(
									'.project-gallery__img-wrapper'
								)
								touchStartX = event.touches[0].pageX
								touchStartY = event.touches[0].pageY

								document.addEventListener('touchmove', e => {
									touchMoveX = e.touches[0].pageX
									touchMoveY = e.touches[0].pageY
								})
							}

							document.ontouchend = event => {
								document.removeEventListener('touchmove', e => {})
							}
						}
					}

					preventContextMenu('.project-gallery__img-wrapper img')

					if (!curRow) {
						curRow = createProjectGalleryRow()
						curRow.appendChild(curImgWrapper)
					} else if (curRow.querySelectorAll('img').length != 2) {
						curRow.appendChild(curImgWrapper)
					} else {
						projectGalleryBox.appendChild(curRow)
						curRow = createProjectGalleryRow()
						curRow.appendChild(curImgWrapper)
						return curRow
					}
				})
				projectGalleryBox.appendChild(curRow)

				projectGalleryBackBtn.onclick = backToProjects

				const projectGalleryResult =
					projectGalleryBox.querySelectorAll('.js-lazy')
				new lazyload(projectGalleryResult, {
					root: null,
				})

				hideElement(activeSection, 400)
				document.documentElement.scrollTo({
					top: 0,
					behavior: 'smooth',
				})
				showElement(projectGallery, 400)

				setTimeout(
					() => projectGallery.classList.add('project-gallery_active'),
					600
				)

				const curProjectGalleryOnPage = document.querySelectorAll(
					'.project-gallery__img'
				)

				const curPopupSource = projectGallery.dataset.imageSource
				const curPopup = document.querySelector(
					`.popup__wrapper#proj${curPopupSource}-popup`
				)

				curProjectGalleryOnPage.forEach((img, idx, curArr) => {
					img.onclick = () => {
						function moveSlide() {
							popupTracks[
								curPopupSource - 1
							].style.transform = `translateX(${translateValue}px)`
						}

						popupWrappers.forEach(wrapper => {
							wrapper.classList.remove('popup__wrapper_active')
						})

						curPopup.classList.add('popup__wrapper_active')
						popupButtons.classList.add('popup__buttons_active')
						popupPrevBtn.classList.add('popup__btn_active')
						popupNextBtn.classList.add('popup__btn_active')

						hideElement(projectGallery, 400)
						showElement(popup, 100)
						showElement(curPopup, 600, 'flex')
						showElement(popupButtons, 1000)
						showElement(popupPrevBtn, 1000)
						showElement(popupNextBtn, 1000)

						const items = document.querySelectorAll(
							`.item${curPopupSource - 1}`
						)
						const itemCount = items.length

						let translateValue = 0

						setTimeout(() => {
							const wrapperWidth = curPopup.clientWidth
							translateValue = -wrapperWidth * idx
							moveSlide()
						}, 0)

						setTimeout(() => {
							const wrapperWidth = curPopup.clientWidth
							popupBtns.forEach(btn => {
								btn.style.width = (popup.clientWidth - wrapperWidth) / 2
							})
						}, 0)

						popupPrevBtn.onclick = () => {
							const slideWidth = curPopup.clientWidth
							if (translateValue === 0) {
								translateValue = -slideWidth * (itemCount - 1)
								moveSlide()
							} else {
								translateValue += slideWidth
								moveSlide()
							}
						}

						popupNextBtn.onclick = () => {
							const slideWidth = curPopup.clientWidth
							if (translateValue === -slideWidth * (itemCount - 1)) {
								translateValue = 0
								moveSlide()
							} else {
								translateValue -= slideWidth
								moveSlide()
							}
						}

						popupDeactivator.onclick = () => {
							translateValue = 0

							setTimeout(() => {
								moveSlide()
							}, 400)

							hideElement(curPopup, 400)
							curPopup.classList.remove('popup__wrapper_active')

							popupBtns.forEach(btn => {
								btn.classList.remove('popup__btn_active')
							})

							popupButtons.classList.remove('popup__buttons_active')
							curPopup.classList.remove('popup__wrapper_active')
							popup.classList.remove('popup_active')
							popupButtons.style.opacity = 0

							hideElement(popup, 400)
							showElement(projectGallery, 400)
						}

						popupCloseBtn.onclick = () => {
							popupDeactivator.onclick()
						}
					}
				})
			}
		})

		popupWrappers.forEach(wrapper => {
			wrapper.addEventListener('touchstart', handleTouchStart, false)
			wrapper.addEventListener('touchmove', handleTouchMove, false)
			wrapper.addEventListener('touchend', handleTouchEnd, false)
		})

		let touchStartX = null
		let touchMoveX = null
		let touchDiffX = null

		function handleTouchStart(event) {
			touchStartX = event.touches[0].clientX
		}

		function handleTouchMove(event) {
			if (!touchStartX) return
			touchMoveX = event.touches[0].clientX
			touchDiffX = touchMoveX - touchStartX
		}

		function handleTouchEnd(event) {
			if (touchDiffX > -50 && touchDiffX < 50) return
			if (touchDiffX >= 50) {
				popupPrevBtn.click()
			} else if (touchDiffX <= -50) {
				popupNextBtn.click()
			}
			touchDiffX = null
		}

		menuBtn.onclick = () => {
			menu.classList.toggle('menu_active')
			menuBtn.classList.toggle('menu-btn_active')
		}

		menu.onclick = () => {
			menu.classList.remove('menu_active')
			menuBtn.classList.remove('menu-btn_active')
		}

		const bottomContactsLink = document.querySelectorAll(
			'.bottom-button[data-link = contactsLink]'
		)
		const bottomWorkLink = document.querySelectorAll(
			'.bottom-button[data-link = workLink]'
		)

		const contactsLink = document.querySelector('.contacts-link')

		bottomContactsLink.forEach(btn => {
			btn.onclick = () => {
				contactsLink.click()
				document.documentElement.scrollTop = 0
			}
		})

		bottomWorkLink.forEach(btn => {
			btn.onclick = () => {
				workMenuLink.click()
				document.documentElement.scrollTop = 0
			}
		})

		const realizationBannerImg = document.querySelector(
			'.realization__banner-image img'
		)
		let realizationBannerTransform

		if (document.documentElement.clientWidth >= 768) {
			document.addEventListener('scroll', () => {
				realizationBannerTransform = window.scrollY
				realizationBannerImg.style.transform = `translateY(${
					realizationBannerTransform - 10
				}px)`
			})
		}

		window.addEventListener('resize', () => {
			if (document.documentElement.clientWidth >= 768) {
				document.addEventListener('scroll', () => {
					realizationBannerTransform = window.scrollY
					realizationBannerImg.style.transform = `translateY(${
						realizationBannerTransform - 10
					}px)`
				})
			}
		})
	}

	app()
	initPageSlider()
	preventContextMenu('img')
})
